<template>
  <Card title="Reset Password Email Sent">  
    <Form>
      <p>We have sent a password reset to {{this.email}}. Please proceed with the instruction given in the email.</p>
      <!-- <SubmitButton style="margin: 0 auto">Verify My Email</SubmitButton> -->
      <div>
          No email in your inbox or spam folder?
         <div class="resend" @click="resendEmail">Let's resend it</div>
      </div>
     
    </Form>
  </Card> 
</template>

<script>
import Card from '@/components/Card';
import Form from '@/components/Form';

import {eventBus} from '@/main.js';

//API
import { forgotPassword } from '@/services/api/users.js';
export default {
  components:{
    Card,Form
  },
  methods:{
    async resendEmail(){
      try {
        eventBus.$emit('loader',true);
        await forgotPassword(this.email);
        eventBus.$emit('loader',false);
        this.$swal({
          icon: 'success',
          title: 'Email sent',
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
        })
        
      } catch (error) {
        eventBus.$emit('loader',false);
        this.$swal({
          icon: 'error',
          title: 'Oops',
          text: error.response.data.userMessage
        })
      }
    }
  },
  created(){
    this.email = this.$route.params.email;

    if(!this.email){
      this.$router.push({name: 'Login'})
    }
  }
}
</script>

<style lang="scss" scoped>
form{
  p{
    max-width: 350px;
    text-align: center;
    padding: 1rem 0 2.5rem;
  }

  .resend{
    cursor: pointer;
    color: var(--redC41230);
    text-decoration: underline;
    margin-top: 0;
  }

  div{
    text-align: center;
    margin-top: 1.5rem;
  }
}
</style>